import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/pt';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PerfilamentoService } from '@app/services/perfilamento/perfilamento.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppRoutingController } from './pages/app/app.routing.controller';
import { AppService } from './pages/app/app.service';
import { AppAuthGuard } from './services/auth.guard';
import { DominioService } from './services/dominio/dominio.service';
import { OauthService } from './services/oauth/oauth.service';
import { ProcessosModule } from './services/processos/processos.module';
import { SessionStorageService } from './services/sessionstorage.service';
import { ProdutoSimilarAgregadoService } from './services/vendas/produto-similar-agregado.service';
import { AuthInterceptor } from './shared/auth/auth-interceptor';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';
import { DateConverterInterceptor } from './shared/interceptors/date-converter-interceptor';
import { CustomReuseStrategy } from './shared/router/custom-reuse-strategy';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    ProcessosModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,

      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    OauthService,
    AppService,
    DominioService,
    AppRoutingController,
    SessionStorageService,
    AppAuthGuard,
    PerfilamentoService,
    ProdutoSimilarAgregadoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateConverterInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;

    registerLocaleData(locale);
  }
}
