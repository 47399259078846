import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NavigationExtras, Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import { ProcessosService } from '@app/services/processos/processos.service';
import { FormularioUnsubscribeUtil } from '@bower-components/astutus-formulario/formulario-unsubscribe.util';
import { SessionStorageService } from '@services/sessionstorage.service';
import { isNullOrUndefined } from 'is-what';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmitirDocumentParams } from '../documents/emitir-document/emitir-document.component';

/**
 * Controle para adicionar rotas e navegar.
 */
@Injectable()
export class AppRoutingController extends FormularioUnsubscribeUtil {
  /**
   * Indica se uma navegação deverá ser realizada.
   *
   * @type {EventEmitter<any>}
   */
  private readonly shouldNavigate: EventEmitter<{
    pageContent: any;
    opts?: Array<any>;
    extras?: NavigationExtras;
  }> = new EventEmitter();

  readonly FORBIDDEN_ACCESS_MESSAGE = 'Seu usuário não possui acesso a esse módulo';

  constructor(
    private router: Router,
    private http: HttpClient,
    private sessionService: SessionStorageService,
    private processosService: ProcessosService,
    private snackBar: MatSnackBar
  ) {
    super();
    this.navigationListener();
  }

  /**
   * Verifica pra onde deve navegar, caso não tenha feito cache anteriormente dos dados da tela, baixa e manda pra tela.
   */
  // TODO documentação confusa, necessita melhor detalhamento.
  navigate(formulario) {
    sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
    this.router.navigate([formulario.dsUrl]);
  }

  /**
   * @deprecated
   * @param url
   */
  goTo(url) {
    this.router.navigate([url]);
  }

  /**
   * Recupera os dados do formulário de cadastro e então emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   */
  async goToCadastro(
    path,
    opts?: any[],
    type = 'pesquisa',
    extras: NavigationExtras = {}
  ): Promise<void> {
    let pageContent = this.sessionService.getItem(path);

    // Garante que as informações da rota sejam carregadas antes de tentar acessa-la.
    if (isNullOrUndefined(pageContent)) {
      const formulario = (await this.getFormulario(path, type).toPromise()).data;
      sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
      pageContent = formulario;
    }

    const hasAccessByUrl = this.processosService.hasAccessByUrl(path);
    const hasAccessByFormularioPrincipal =
      !isNullOrUndefined(pageContent.formularioPrincipal) &&
      this.processosService.hasAccessByUrl(pageContent.formularioPrincipal.dsUrl);

    if (!hasAccessByUrl && !hasAccessByFormularioPrincipal) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return;
    }

    this.shouldNavigate.emit({ pageContent, opts, extras });
  }

  /**
   * Recupera os dados do formulário de edição e então emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   * @param type - id do objeto a ser editado
   */
  async goToUpdate(path, opts?: Array<any>, type = 'pesquisa'): Promise<void> {
    let pageContent = this.sessionService.getItem(path);
    let aux;

    if (isNullOrUndefined(pageContent)) {
      const formulario = (await this.getFormulario(path, type).toPromise()).data;
      sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
      pageContent = formulario;
    }

    const hasAccessByUrl = this.processosService.hasAccessByUrl(path);
    const hasAccessByFormularioPrincipal =
      !isNullOrUndefined(pageContent.formularioPrincipal) &&
      this.processosService.hasAccessByUrl(pageContent.formularioPrincipal.dsUrl);

    if (!hasAccessByUrl && !hasAccessByFormularioPrincipal) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return;
    }

    aux = JSON.parse(JSON.stringify(pageContent));
    aux.formularioCadastro = null;
    this.shouldNavigate.emit({ pageContent: aux, opts: ['update', ...opts] });
  }

  /**
   * Recupera os dados do formulário de pesquisa e faz a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   */
  async goToPesquisa(path: string): Promise<void> {
    let pageContent = this.sessionService.getItem(path);

    // Garante que as informações da rota sejam carregadas antes de tentar acessa-la.
    if (isNullOrUndefined(pageContent)) {
      const formulario = (await this.getFormulario(path, 'pesquisa').toPromise()).data;
      sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
      pageContent = formulario;
    }

    const hasAccessByUrl = this.processosService.hasAccessByUrl(path);
    const hasAccessByFormularioPrincipal =
      !isNullOrUndefined(pageContent.formularioPrincipal) &&
      this.processosService.hasAccessByUrl(pageContent.formularioPrincipal.dsUrl);

    if (!hasAccessByUrl && !hasAccessByFormularioPrincipal) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return;
    }

    this.router.navigate([path]);
  }

  /**
   * Recupera os dados do formulário e emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   * @param type - id do objeto a ser editado
   */
  goToDetails(path, opts?: Array<any>, type = 'cadastro'): void {
    if (!this.processosService.hasAccessByUrl(path)) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return;
    }

    const pageContent = this.sessionService.getItem(path);
    let aux;
    if (isNullOrUndefined(pageContent)) {
      this.getFormulario(path, type).subscribe((formulario: any) => {
        sessionStorage.setItem(formulario.data.dsUrl, JSON.stringify(formulario.data));
        aux = JSON.parse(JSON.stringify(formulario.data));
        aux.formularioCadastro = null;
        this.shouldNavigate.emit({ pageContent: aux, opts });
      });
    }
    if (!isNullOrUndefined(pageContent)) {
      aux = JSON.parse(JSON.stringify(pageContent));
      aux.formularioCadastro = null;
      this.shouldNavigate.emit({ pageContent: aux, opts });
    }
  }

  /**
   * Realiza o request pra buscar os dados da tela.
   */
  public getFormulario(dsUrl: string, type: string): Observable<any> {
    const url = `${AppConfig.ASTUTUS_API_URL}/formulario/${type}/consulta?dsUrl=${dsUrl}`;
    return this.http.get(url);
  }

  /**
   * Adiciona um observable para identificar se deve navegar para um outra rota.
   */
  private navigationListener(): void {
    this.shouldNavigate.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      const { pageContent } = data;
      const { opts } = data;
      const { extras } = data;

      const url = pageContent.formularioCadastro
        ? pageContent.formularioCadastro.dsUrl
        : pageContent.dsUrl;
      if (opts) {
        this.router.navigate([url, ...opts], extras);
      }

      if (!opts) {
        this.router.navigate([url], extras);
      }
    });
  }

  /**
   * Abre PDF do relatório
   * @param data
   */
  navigeteToReport(data) {
    const nmFilename = data.nmFilename.replace('/s3arquivo/download/', '').replace('.pdf', '');
    this.goToCadastro('/app/pdf-viewer/file-name', [nmFilename]);
  }

  gotToEmitirDocumento(params: EmitirDocumentParams) {
    this.goToCadastro(
      '/app/documento/emitir/new',
      [params.documentType, params.fieldType, params.fieldName, params.filterValue],
      'cadastro'
    );
  }

  private showMessage(msg: string) {
    this.snackBar.open(msg, 'OK', {
      duration: 3000,
    });
  }
}
